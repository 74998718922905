import { kea } from 'kea'
import { router } from 'kea-router'
import axios from 'axios'
import { message } from 'antd'
import jwt from 'jwt-simple'
import Cookies from 'js-cookie'

import { isObjectEmpty } from '../lib/utils/isObjectEmpty'

import { Scene, Params } from './types'
import { routes } from './routes'

import { scenesLogicType } from './scenesLogicType'
import { authTokenKey, localResumeKey, jwtSecret } from '../secretKeys'

import { userLogic } from './userLogic'

const baseURL = process.env.NODE_ENV === 'production' ? 'https://api.nanoresume.com' : 'http://localhost:8000'

export const scenesLogic = kea<scenesLogicType>({
  actions: {
    setScene: (scene: Scene, params: Params) => ({ scene, params }),
    confirmEmail: (token: string) => ({ token }),
  },

  urlToAction: ({ actions }) => {
    const routesMap: Record<string, (params: Params) => any> = {}

    for (const [path, scene] of Object.entries(routes)) {
      routesMap[path] = (params) => actions.setScene(scene, params)
    }

    routesMap['/preview/:uid'] = async ({ uid }) => {
      const authToken = Cookies.get(authTokenKey)
      const decodedAuthToken = authToken ? jwt.decode(authToken, jwtSecret) : ''
      const user = !decodedAuthToken
        ? {}
        : typeof decodedAuthToken === 'string'
        ? JSON.parse(decodedAuthToken)
        : decodedAuthToken.user

      if (authToken) {
        try {
          const resumeURL = `${baseURL}/api/users/${user?._id}/resumes/${uid}`

          const response: any = (await axios.get(resumeURL)) || {}
          const resume = response.data.resume

          if (!!resume) {
            actions.setScene(Scene.DocumentEditor, { resume, isPreview: true })
          } else {
            actions.setScene(Scene.Error404, {})
          }
        } catch (e) {
          actions.setScene(Scene.ErrorNetwork, {})
        }
      }
    }

    routesMap['/editor/:uid'] = async ({ uid }) => {
      const localResume = window.localStorage.getItem(localResumeKey)!
      const authToken = Cookies.get(authTokenKey)
      const decodedAuthToken = authToken ? jwt.decode(authToken, jwtSecret) : ''
      const user = !decodedAuthToken
        ? {}
        : typeof decodedAuthToken === 'string'
        ? JSON.parse(decodedAuthToken)
        : decodedAuthToken.user

      const resumeURL = `${baseURL}/api/users/${user?._id}/resumes/${uid}`

      const isAuthenticated = !isObjectEmpty(user)

      if (isAuthenticated) {
        if (!!localResume) {
          const resume = JSON.parse(localResume)
          actions.setScene(Scene.DocumentEditor, { resume })
        } else {
          try {
            const response: any = (await axios.get(resumeURL)) || {}
            const resume = response.data.resume

            if (!!resume) {
              actions.setScene(Scene.DocumentEditor, { resume })
            } else {
              actions.setScene(Scene.Error404, {})
            }
          } catch (e) {
            actions.setScene(Scene.ErrorNetwork, {})
          }
        }
      } else {
        actions.setScene(Scene.DocumentEditor, { resume: localResume })
      }
    }

    // routesMap['/authentication/:token'] = ({ token }) => actions.authenticateThirdParty(token)

    routesMap['/confirmation/:token'] = ({ token }) => actions.confirmEmail(token)

    routesMap['/reset-password/:token'] = ({ token }) => actions.setScene(Scene.ResetPassword, { token })

    routesMap['/*'] = () => actions.setScene(Scene.Error404, {})

    return routesMap
  },

  reducers: {
    scene: [
      '' as Scene,
      {
        setScene: (_, payload) => payload.scene,
      },
    ],
    params: [
      {} as Params,
      {
        setScene: (_, payload) => payload.params || {},
      },
    ],
  },

  listeners: ({ values, actions }) => ({
    setScene: () => {
      const sceneTitle = {
        dashboard: 'Dashboard',
        accountSettings: 'Setting',
        onboarding: 'Onboarding',
        login: 'Login',
        signup: 'Signup',
        forgotPassword: 'Forgot Password',
        resetPassword: 'Reset Password',
        unvalidURL: 'Unvalid URL',
        documentEditor: 'Editor',
      }
      document.title = values.scene
        ? `${sceneTitle[values.scene]} - nanoresume`
        : 'nanoresume: Free Online Resume Creator'
    },

    confirmEmail: async ({ token }) => {
      const authToken = Cookies.get(authTokenKey)
      const decodedAuthToken = jwt.decode(authToken, jwtSecret)
      const user = typeof decodedAuthToken === 'string' ? JSON.parse(decodedAuthToken) : decodedAuthToken.user
      const uid = jwt.decode(token, jwtSecret)

      const sameAccount = user.uid === uid
      const emailAlreadyConfirmed = user.isVerified

      if (!sameAccount || emailAlreadyConfirmed) {
        actions.setScene(Scene.UnvalidURL, {})
        return
      }

      const emailConfirmationUrl = `${baseURL}/api/users/confirmation/${token}`

      try {
        await axios.patch(emailConfirmationUrl, { uid })

        const updatedUser = JSON.stringify({ ...user, isVerified: true })
        const encryptUser = jwt.encode(updatedUser, jwtSecret)

        Cookies.set(authTokenKey, encryptUser)

        router.actions.push('/')

        message.success('Congratulations, Your email address has been verified', 3)
      } catch (e) {
        message.error('Something went wrong', 3)
        actions.setScene(Scene.ErrorNetwork, {})
      }
    },
  }),
})
