import React from 'react'
import { Result } from 'antd'

export function Error404() {
  return (
    <div>
      {' '}
      <Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." />
    </div>
  )
}
