import React, { lazy, Suspense, useMemo } from 'react'
import { useValues } from 'kea'

import { scenes } from './scenes'
import { scenesLogic } from './scenesLogic'
import { userLogic } from './userLogic'

import Loading from '../lib/components/Loading'

export function App(): JSX.Element {
  const { scene, params } = useValues(scenesLogic)
  const { user } = useValues(userLogic)

  const Scene = useMemo(() => {
    return scenes[scene] ? lazy(scenes[scene]) : () => <div>404</div>
  }, [scene])

  return (
    <div className="App">
      <Suspense fallback={<Loading />}>
        <Scene {...params} user={user} />
      </Suspense>
    </div>
  )
}
