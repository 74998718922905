import { Scene, LoadedScene } from './types'

import { Error404 as Error404Component } from '../lib/components/Error404'
import { ErrorNetwork as ErrorNetworkComponent } from '../lib/components/ErrorNetwork'

export const preloadedScenes: Record<string, LoadedScene> = {
  [Scene.Error404]: {
    component: Error404Component,
  },
  [Scene.ErrorNetwork]: {
    component: ErrorNetworkComponent,
  },
}

export const scenes: Record<Scene, () => Promise<any>> = {
  [Scene.Dashboard]: () => import(/* webpackChunkName: 'dashboard' */ './dashboard/Dashboard'),
  [Scene.DocumentEditor]: () => import(/* webpackChunkName: 'document-editor' */ './document-editor/DocumentEditor'),
  [Scene.Onboarding]: () => import(/* webpackChunkName: 'onboarding' */ './onboarding/Onboarding'),
  [Scene.AccountSettings]: () =>
    import(/* webpackChunkName: 'account-settings' */ './account-settings/AccountSettings'),
  [Scene.Login]: () => import(/* webpackChunkName: 'login' */ './login/Login'),
  [Scene.Signup]: () => import(/* webpackChunkName: 'signup' */ './signup/Signup'),
  [Scene.ForgotPassword]: () => import(/* webpackChunkName: 'forgot-password' */ './forgot-password/ForgotPassword'),
  [Scene.ResetPassword]: () => import(/* webpackChunkName: 'reset-password' */ './reset-password/ResetPassword'),
  [Scene.Error404]: () =>
    import(/* webpackChunkName: 'Error404' */ '../lib/components/Error404').then((module) => ({
      default: module.Error404,
    })),
  [Scene.ErrorNetwork]: () =>
    import(/* webpackChunkName: 'ErrorNetwork' */ '../lib/components/ErrorNetwork').then((module) => ({
      default: module.ErrorNetwork,
    })),
  [Scene.UnvalidURL]: () =>
    import(/* webpackChunkName: 'UnvalidURL' */ '../lib/components/UnvalidURL').then((module) => ({
      default: module.UnvalidURL,
    })),
}
