import { Scene } from './types'

export const routes: Record<string, Scene> = {
  '/': Scene.Dashboard,
  '/onboarding': Scene.Onboarding,
  '/account-settings': Scene.AccountSettings,
  '/login': Scene.Login,
  '/signup': Scene.Signup,
  '/forgot-password': Scene.ForgotPassword,
  '/reset-password': Scene.ResetPassword,
}
