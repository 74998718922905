import { kea } from 'kea'
import { router } from 'kea-router'
import jwt from 'jwt-simple'
import Cookies from 'js-cookie'

import { authTokenKey, jwtSecret } from '../secretKeys'

import { UserType } from './types'
import { userLogicType } from './userLogicType'

export const userLogic = kea<userLogicType>({
  actions: {
    loadUser: true,
    logout: true,
  },

  loaders: () => ({
    user: [
      null as UserType | null,
      {
        loadUser: () => {
          const authToken = Cookies.get(authTokenKey)

          if (authToken) {
            const decodedAuthToken = jwt.decode(authToken, jwtSecret)
            const user = typeof decodedAuthToken === 'string' ? JSON.parse(decodedAuthToken) : decodedAuthToken.user

            return user
          }

          return null
        },

        logout: () => {
          Cookies.remove(authTokenKey)

          router.actions.push('/login')

          for (let key in window.localStorage) {
            if (
              key.includes('scenes.document-editor') ||
              key.includes('scenes.dashboard') ||
              key === 'resume-content'
            ) {
              window.localStorage.removeItem(key)
            }
          }

          return null
        },
      },
    ],
  }),

  events: ({ actions }) => ({
    afterMount: () => {
      actions.loadUser()
    },
  }),
})
