import { LogicWrapper } from 'kea'

export interface Params {
  [param: string]: any
}

export enum Scene {
  Error404 = '404',
  ErrorNetwork = '4xx',
  Dashboard = 'dashboard',
  DocumentEditor = 'documentEditor',
  Onboarding = 'onboarding',
  AccountSettings = 'accountSettings',
  Login = 'login',
  Signup = 'signup',
  ForgotPassword = 'forgotPassword',
  ResetPassword = 'resetPassword',
  UnvalidURL = 'unvalidURL',
}

export interface LoadedScene {
  component: () => JSX.Element
  logic?: LogicWrapper
}

export interface UserType {
  _id: string
  uid: string
  email: string
  firstName: string
  lastName: string
  username: String
  isVerified: boolean
  isOnBoardingDone: boolean
  photoUrl: string
  professionalTitle: string
  bio: string
  weeklyTips: boolean
  weeklyNewsLetter: boolean
}
