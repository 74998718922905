import { resetContext } from 'kea'
import { routerPlugin } from 'kea-router'
import { loadersPlugin } from 'kea-loaders'
import { localStoragePlugin } from 'kea-localstorage'

export function initKea(): void {
  resetContext({
    plugins: [
      routerPlugin({
        urlPatternOptions: {
          segmentValueCharset: 'a-zA-Z0-9-_~ %=.',
        },
      }),
      loadersPlugin,
      localStoragePlugin,
    ],
    createStore: {
      paths: ['kea', 'scenes'],
    },
  })
}
