import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { getContext } from 'kea'

import { initKea } from './initKea'
import { App } from './scenes/App'

import 'antd/dist/antd.css'
import '@brainhubeu/react-carousel/lib/style.css'
import './index.css'

initKea()

ReactDOM.render(
  <Provider store={getContext().store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
